import * as Types from '../../graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

/*
* This was automatically generated on by @graphql-codegen.
* DO NOT MANUALLY EDIT !!!
* Please refer to https://github.com/wistia/wistia/blob/main/frontend/graphql/README.md for more information.
* Last Updated: Tue Apr 08 2025 07:10:59 GMT+0000 (Coordinated Universal Time)
*/

const defaultOptions = {} as const;
export type GetGtmDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGtmDataQuery = { __typename?: 'Query', currentAccount?: { __typename?: 'Account', id: string, numericId: number, churnZeroAccountId?: string | null | undefined, type: Types.AccountSubclass, plan?: { __typename?: 'Plan', id: string, sku?: string | null | undefined } | null | undefined } | null | undefined, currentContact?: { __typename?: 'Contact', id: string, numericId: number, numericUserId: number, email: string, isSuperadmin: boolean, isOwner: boolean, isWadmin: boolean } | null | undefined };

export type AnonymousGetGtmDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AnonymousGetGtmDataQuery = { __typename?: 'Query', currentAccount?: { __typename?: 'Account', id: string, numericId: number } | null | undefined };


export const GetGtmDataDocument = gql`
    query GetGTMData {
  currentAccount {
    id
    numericId
    churnZeroAccountId
    type
    plan {
      id
      sku
    }
  }
  currentContact {
    id
    numericId
    numericUserId
    email
    isSuperadmin
    isOwner
    isWadmin
  }
}
    `;

/**
 * __useGetGtmDataQuery__
 *
 * To run a query within a React component, call `useGetGtmDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGtmDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGtmDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGtmDataQuery(baseOptions?: Apollo.QueryHookOptions<GetGtmDataQuery, GetGtmDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGtmDataQuery, GetGtmDataQueryVariables>(GetGtmDataDocument, options);
      }
export function useGetGtmDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGtmDataQuery, GetGtmDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGtmDataQuery, GetGtmDataQueryVariables>(GetGtmDataDocument, options);
        }
export function useGetGtmDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGtmDataQuery, GetGtmDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGtmDataQuery, GetGtmDataQueryVariables>(GetGtmDataDocument, options);
        }
export type GetGtmDataQueryHookResult = ReturnType<typeof useGetGtmDataQuery>;
export type GetGtmDataLazyQueryHookResult = ReturnType<typeof useGetGtmDataLazyQuery>;
export type GetGtmDataSuspenseQueryHookResult = ReturnType<typeof useGetGtmDataSuspenseQuery>;
export type GetGtmDataQueryResult = Apollo.QueryResult<GetGtmDataQuery, GetGtmDataQueryVariables>;
export const AnonymousGetGtmDataDocument = gql`
    query AnonymousGetGTMData {
  currentAccount {
    id
    numericId
  }
}
    `;

/**
 * __useAnonymousGetGtmDataQuery__
 *
 * To run a query within a React component, call `useAnonymousGetGtmDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnonymousGetGtmDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnonymousGetGtmDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnonymousGetGtmDataQuery(baseOptions?: Apollo.QueryHookOptions<AnonymousGetGtmDataQuery, AnonymousGetGtmDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnonymousGetGtmDataQuery, AnonymousGetGtmDataQueryVariables>(AnonymousGetGtmDataDocument, options);
      }
export function useAnonymousGetGtmDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnonymousGetGtmDataQuery, AnonymousGetGtmDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnonymousGetGtmDataQuery, AnonymousGetGtmDataQueryVariables>(AnonymousGetGtmDataDocument, options);
        }
export function useAnonymousGetGtmDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AnonymousGetGtmDataQuery, AnonymousGetGtmDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AnonymousGetGtmDataQuery, AnonymousGetGtmDataQueryVariables>(AnonymousGetGtmDataDocument, options);
        }
export type AnonymousGetGtmDataQueryHookResult = ReturnType<typeof useAnonymousGetGtmDataQuery>;
export type AnonymousGetGtmDataLazyQueryHookResult = ReturnType<typeof useAnonymousGetGtmDataLazyQuery>;
export type AnonymousGetGtmDataSuspenseQueryHookResult = ReturnType<typeof useAnonymousGetGtmDataSuspenseQuery>;
export type AnonymousGetGtmDataQueryResult = Apollo.QueryResult<AnonymousGetGtmDataQuery, AnonymousGetGtmDataQueryVariables>;